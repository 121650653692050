html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  font-family: Helvetica;
  background-color: #2e2e2e;
  color: rgb(54, 54, 54);
  padding-bottom: env(safe-area-inset-bottom);
}

.mainContainer {
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 20px);
  min-height: calc(100% - 20px);
  font-size: 1em;
  background: linear-gradient(120deg, #c6c4ca, #e9e7ee);
  position: absolute;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px;
}

.hostListContainer {
  height: 450px;
  padding: 20px;
  width: 90%;
  background-color: #a3a5a6;
  margin: 5px;
  display: flex;
}

.hostSectionContainer {
  height: 450px;
  text-align: center;
  padding: 20px;
  width: 90%;
  background-color: #acadae;
  margin: 5px;
  overflow-y: auto;
}

.hostList {
  text-align: left;
  width: 30%;
  margin: 20px;
  overflow-y: auto;
}

.hostListPlayer {
  text-align: left;
  width: 70%;
  margin: 20px;
  overflow-y: auto;
}

.hostBuzzerLockStatus {
  width: 80px;
  height: 80px;
}

.hostBuzzerControl {
  font-size: 20px;
  height: 80px;
  width: 80px;
}

.hostBuzzerButtonsContainter {
  display: flex;
  gap: 10px;
}

.buzzesContainer h2 {
  margin: 0;
}
.buzzesContainer {
  align-items: center;
  text-align: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #a29f99;
  color: #ffffff;
}
.buzzesList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buzzesList li {
  letter-spacing: 1px;
  width: 90%;
  background-color: #969490;
  padding: 6px;
  border-radius: 10px;
}

.buzzesNumber {
  color: #ffffff;
  display: flex;
  text-align: center;
  background-color: #000000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.buzzesList h5 {
  font-size: 24px;
  margin: 4px;
}
.buzzesList h4 {
  margin: 5px;
  font-size: 24px;
}
.buzzesContainer h2 {
  margin: 30px;
}

.buzzesList li {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
}

li {
  margin: 3px;
  list-style-type: none;
}

ul {
  margin: 10px;
  padding: 0;
}

ol {
  padding: 0;
  margin: 0;
}

.buzzesTableContainer {
  display: flex;
  width: 100%;
  height: 100vh;

  color: #fff;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* broadcast board  */
.squadGamesLogo,
.squadPoints {
  flex: 1; /* Each div takes up equal space */
}

.squadGamesLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 150px;
}

.squadPoints {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 150px;
}

.squadPoints li {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  padding: 0px 0px 0px 7px;
  letter-spacing: 1px;
  width: 550px;
  height: 60px;
  border-radius: 10px;
}

.squadPoints h5 {
  font-size: 50px;
  margin: 0px;
  padding: 0px;
  margin-right: 0px;
}

.playerAndTeam {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.buzzesFrom {
  font-size: 30px;
  padding: 0px 9px;
}

.buzzesSeconds {
  font-size: 20px;
  padding-left: 9px;
  color: #3af883;
}

.broadcastNumber {
  margin-right: 0px;
  border: 0px;
  border-radius: 0px 10px 10px 0px;
  width: 80px;
  font-size: 30px;
  color: #ffffff;
  display: flex;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  height: 60px;
  align-items: center;
  justify-content: center;
  margin-left: auto; /* Aligns broadcastNumber to the right */
}

.buzzesNumber {
  margin: 0px;
  border: 0px;
  border-radius: 10px 0px 0px 10px;
  width: 80px;
  font-size: 30px;
  color: #ffffff;
  display: flex;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  height: 60px;
  align-items: center;
  justify-content: center;
}

.underImageText {
  color: #000000;
  margin: 40px;
  font-size: 40px;
  letter-spacing: 2px;
}

/* keep the following for the entire app  */

h3,
h4,
h5 {
  margin: 0px;
}
