.formContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
}

.formContainer p {
  color: #ffffff;
}

input[type='text'],
select,
textarea {
  align-items: center;
  min-width: 300px;
  min-height: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
  margin: 6px;
}

button[type='submit'] {
  background-color: #c1bdb3;
  padding: 12px 20px;
  margin: 4px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

::placeholder {
  color: black;
  opacity: 1;
}
