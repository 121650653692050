.play-container {
  color: black;
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 20px);
  min-height: calc(100% - 20px);
  font-size: 1em;
  box-shadow: inset 8px 8px 40px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient(120deg, #c6c4ca, #e9e7ee);
  position: absolute;
  color: 'black';
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px;
}
