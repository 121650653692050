.player-details-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  /* margin-bottom: 20px; */
}

.player-details-container h4 {
  font-size: 11px;
}

.title-pair {
  margin: 7px;
}
.player-details-container h5 {
  font-size: 18px;
  margin: 2px;
}
