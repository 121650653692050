.connection-live-text {
  font-size: 14px;
  color: #626262;
  text-shadow: 1px 1px 1px #c1c1c1;
}

.front-disc {
  font-size: 24px;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 290px;
  height: 290px;
  z-index: 2;
  background: #f2f0f6;
  backdrop-filter: blur(24px);
  border-radius: 50%;
  overflow: hidden;
  align-content: center;
  box-shadow: inset 8px 8px 17px 0 rgba(0, 0, 0, 0.05),
    inset -13px -13px 12px 0 hsla(0, 0%, 100%, 0.65);
  color: #626262;
  text-shadow: 1px 1px 1px #c1c1c1;
}

.front-disc:hover {
  background: #edebf1;
}

.back-disc {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  z-index: 1111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 27px;
  background-color: #f2f0f6;
  box-shadow: -11px -11px 40px 3px #f6f5f5,
    8px 14px 40px -20px rgba(0, 0, 0, 0.19);
}

.glow-disc,
.glow-disc-animated {
  position: absolute;
  z-index: 1;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  opacity: 1;
  filter: blur(10px);
  animation: glow-circle 5s ease-in-out infinite alternate;
}

.glow-disc-animated {
  animation: glow-circle 2s ease-in-out infinite alternate;
}

@keyframes glow-circle {
  from {
    scale: 88%;
  }
  to {
    scale: 100%;
  }
}

/* @keyframes blob-bounce {
  0% {
    transform: translate(0%, -100%);
  }

  100% {
    transform: translate(0%, 100%);
  }
} */

/* pre */
